import React, { useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

function Colors() {
	useLayoutEffect(() => window.scroll(0, 0), []);
	const [colors, colorsSet] = useState([]);
	const [isLoading, isLoadingSet] = useState(true);

	const { t, i18n } = useTranslation();

	useEffect(() => {
		axios.get("/colors").then((result) => {
			isLoadingSet(false);
			colorsSet(result.data);
		});
	}, []);
	return (
		<div className="container mt-5">
			<h1 className="display-4">{t("colors")}</h1>
			<div className="row mt-5">
				{colors.map((color) => (
					<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3 d-flex flex-column align-items-center">
						<div
							className="rounded shadow"
							style={{
								height: "200px",
								width: "200px",
								backgroundColor: color.value,
							}}
						></div>
						<h3 className="mt-2 font-weight-normal">
							{color.name}
						</h3>
					</div>
				))}
			</div>
			{isLoading && (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-danger"
						style={{
							width: "100px",
							height: "100px",
						}}
						role="status"
					></div>
				</div>
			)}
		</div>
	);
}

export default Colors;
