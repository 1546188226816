import React, { useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import LandingPageCarousel from "./LandingPageCarousel";
// import ProductCard from "./ProductCard";
import axios from "axios";
import { Link } from "react-router-dom";
import ContactUs from "./ContactUs";
import landing1image from "../assets/landing-1.jpg";
import landing2image from "../assets/landing-2.jpg";
import landing3image from "../assets/landing-3.jpeg";
import aboutCompanyimage from "../assets/about-company.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import colorsVideo from "../assets/colors.mp4";
// import LandingPageSwiper from "./LandingPageSwiper";
import LandingPageMap from "./LandingPageMap";

function LandingPage({ sliderImages, companyFeatures, branches, infoData }) {
	useLayoutEffect(() => window.scroll(0, 0), []);

	useEffect(() => {
		AOS.init({ duration: 2000 });
	}, []);

	function isOdd(num) {
		return num % 2;
	}

	const { t, i18n } = useTranslation();
	const [products, productsSet] = useState([]);
	const [categories, categoriesSet] = useState([]);
	const [isLoading, isLoadingSet] = useState(false);
	const [isLoadingCategories, isLoadingCategoriesSet] = useState(false);

	function loadProducts() {
		axios.get("/products?paginate=6").then((result) => {
			isLoadingSet(false);
			productsSet(result.data.data);
		});
	}

	function loadCategories() {
		axios.get("/categories").then((result) => {
			isLoadingCategoriesSet(false);
			categoriesSet(result.data.data);
			console.log(result.data.data);
		});
	}

	useEffect(() => {
		productsSet([]);
		categoriesSet([]);
		isLoadingSet(true);
		isLoadingCategoriesSet(true);
		setTimeout(() => {
			loadCategories();
			AOS.refresh();
		}, 200);
	}, [i18n.language]);

	return (
		<div>
			<LandingPageCarousel sliderImages={sliderImages} />
			{/* <LandingPageSwiper /> */}

			<div className="container mt-5 mb-5">
				<h1 className="font-weight-light mb-5">{t("categories")}</h1>
				<div className="row">
					{/* <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-5">
						<ProductCard />
					</div> */}
					{categories.map((category) => (
						<div
							key={category.id}
							className="col-md-4 col-sm-6 col-xs-12 mb-5 d-flex justify-content-center"
						>
							<Link
								to={`/shop/category/${category.id}`}
								style={{ width: "90%" }}
							>
								<div
									className="category shadow rounded"
									style={{
										backgroundImage: `url(${category.image})`,
									}}
								>
									<div className="category__name">
										{category.name}
									</div>
								</div>
							</Link>
						</div>
					))}
				</div>
				{isLoadingCategories && (
					<div className="d-flex justify-content-center mt-5">
						<div
							className="spinner-border text-danger"
							style={{ width: "100px", height: "100px" }}
							role="status"
						></div>
					</div>
				)}
			</div>

			{/** Video background */}
			<div className="landingPage__colors">
				<video
					src={colorsVideo}
					loop
					autoPlay
					muted
					style={{
						width: "100%",
						height: "512px",
						objectFit: "cover",
						zIndex: "-1",
						position: "absolute",
					}}
				></video>

				<div className="landingPage__colorsText" data-aos="fade-down">
					<h3>{t("landingPageColorsTitle")}</h3>
					<p>{t("landingPageColorsText")}</p>
				</div>
			</div>

			{companyFeatures?.map((item, index) => {
				if (isOdd(index)) {
					return (
						<>
							<div className="container">
								<div
									className="row flex-md-row-reverse"
									style={{ marginTop: "150px" }}
								>
									<div
										className="col-md-5 col-xs-12 offset-md-1 mb-3"
										data-aos="fade-up"
									>
										<h1 className="display-4">
											{item?.title}
										</h1>
										<p className="text-secondary">
											{item?.description}
										</p>
									</div>
									<div
										className="col-md-6 col-xs-12"
										data-aos="flip-left"
									>
										<img
											src={item?.image}
											alt=""
											className="w-100 shadow-lg rounded"
										/>
									</div>
								</div>
							</div>
							<div
								className="container"
								data-aos="fade"
								style={{
									marginTop: "150px",
									marginBottom: "150px",
								}}
							>
								<hr />
							</div>
						</>
					);
				} else {
					return (
						<>
							<div className="container">
								<div className="row mb-5">
									<div
										data-aos="fade-up"
										className="col-md-6 col-xs-12 mb-3"
									>
										<h1 className="display-4">
											{item?.title}
										</h1>
										<p className="text-secondary">
											{item?.description}
										</p>
									</div>
									<div
										className="col-md-6 col-xs-12"
										data-aos="flip-right"
									>
										<img
											src={item?.image}
											alt=""
											style={{ objectFit: "contain" }}
											className="w-100 shadow-lg rounded"
										/>
									</div>
								</div>
							</div>
							<div
								className="container"
								data-aos="fade"
								style={{
									marginTop: "150px",
									marginBottom: "150px",
								}}
							>
								<hr />
							</div>
						</>
					);
				}
			})}

			{/* <div className="container">
				<div className="row mb-5">
					<div data-aos="fade-up" className="col-md-6 col-xs-12 mb-3">
						<h1 className="display-4">{t("slide1Title")}</h1>
						<p className="text-secondary">
							{t("slide1Description")}
						</p>
						<Link
							to="/register"
							className="btn btn-outline-danger mb-5"
						>
							{t("registerNow")}
						</Link>
					</div>
					<div className="col-md-6 col-xs-12" data-aos="flip-right">
						<img
							src={landing1image}
							alt=""
							className="w-100 shadow-lg rounded"
						/>
					</div>
				</div>
				<div
					className="container"
					data-aos="fade"
					style={{ marginTop: "150px", marginBottom: "150px" }}
				>
					<hr />
				</div>
				<div
					className="row flex-md-row-reverse"
					style={{ marginTop: "150px" }}
				>
					<div
						className="col-md-5 col-xs-12 offset-md-1 mb-3"
						data-aos="fade-up"
					>
						<h1 className="display-4">{t("slide2Title")}</h1>
						<p className="text-secondary">
							{t("slide2Description")}
						</p>
						<Link
							to="/shop"
							className="btn btn-outline-danger btn-outlined mb-5"
						>
							{t("shopNow")}
						</Link>
					</div>
					<div className="col-md-6 col-xs-12" data-aos="flip-left">
						<img
							src={landing2image}
							alt=""
							className="w-100 shadow-lg rounded"
						/>
					</div>
				</div>
				<div
					className="container"
					data-aos="fade"
					style={{ marginTop: "150px", marginBottom: "150px" }}
				>
					<hr />
				</div>
				<div className="row" style={{ marginTop: "150px" }}>
					<div className="col-md-6 col-xs-12 mb-3" data-aos="fade-up">
						<h1 className="display-4">{t("slide3Title")}</h1>
						<p className="text-secondary">
							{t("slide3Description")}
						</p>
						<Link
							to="/branches"
							className="btn btn-outline-danger mb-5"
						>
							{t("ourBranches")}
						</Link>
					</div>
					<div className="col-md-6 col-xs-12" data-aos="flip-right">
						<img
							src={landing3image}
							alt=""
							className="w-100 shadow-lg rounded"
						/>
					</div>
				</div>
			</div> */}
			{/* 
			<div className="container mt-5 mb-5">
				<h1 className="font-weight-light mb-5">
					{t("latestProducts")}
				</h1>
				<div className="row">
					
					{products.map((product) => (
						<div
							key={product.id}
							className=" col-md-4 col-sm-6 col-xs-12 mb-5"
						>
							<ProductCard product={product} />
						</div>
					))}
				</div>
				{isLoading && (
					<div className="d-flex justify-content-center mt-5">
						<div
							className="spinner-border text-danger"
							style={{ width: "100px", height: "100px" }}
							role="status"
						></div>
					</div>
				)}
				<div className="d-flex justify-content-center mt-5 mb-5">
					<Link to="/shop" className="btn btn-danger">
						{t("browseAllProducts")}
					</Link>
				</div>
			</div> */}

			{/* <div
				className="container"
				style={{ marginTop: "150px", marginBottom: "150px" }}
			>
				<hr />
			</div> */}

			<div className="container" id="about">
				<h1 className="display-4 text-center">{t("aboutCompany")}</h1>
				<div className="row" style={{ marginTop: "150px" }}>
					<div className="col-md-6 col-xs-12 mb-3" data-aos="fade-up">
						<p className="text-secondary">
							{i18n.language === "en"
								? infoData?.about_en
								: infoData?.about_ar}
						</p>
					</div>
					<div className="col-md-6 col-xs-12" data-aos="flip-right">
						<img
							src={infoData?.about_image}
							alt=""
							className="w-100 shadow-lg rounded"
						/>
					</div>
				</div>
			</div>

			<LandingPageMap branches={branches} />

			{/* <div
				className="container"
				data-aos="fade"
				style={{ marginTop: "150px", marginBottom: "150px" }}
			>
				<hr />
			</div> */}

			<ContactUs />
		</div>
	);
}

export default LandingPage;
