import React from "react";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";

import easy_to_apply_png from "../assets/product-icons/easy-to-apply.png";
import eco_friendly_png from "../assets/product-icons/eco-friendly.png";
import excellent_adhesion_png from "../assets/product-icons/excellent-adhesion.png";
import excellent_washability_png from "../assets/product-icons/excellent-washability.png";
import high_durability_png from "../assets/product-icons/high-durability.png";
import multiple_colors_png from "../assets/product-icons/multiple-colors.png";
import multiple_designs_png from "../assets/product-icons/multiple-designs.png";
import odorless_png from "../assets/product-icons/odorless.png";
import resistance_to_efflorescence_png from "../assets/product-icons/resistance-to-efflorescence.png";
import stain_resistant_png from "../assets/product-icons/stain-resistant.png";

function ProductCard({ product }) {
	const { t } = useTranslation();
	return (
		<Card>
			<Card.Img
				variant="top"
				src={product.image}
				style={{
					height: "280px",
					width: "100%",
					objectFit: "cover",
				}}
			/>
			<Card.Body className="d-flex flex-column">
				<Card.Title>{product.name}</Card.Title>

				<Rating value={product?.rate} readOnly />
				<Card.Text style={{ flex: "1" }}>
					{product.short_description}
				</Card.Text>
				{/* <div
					className="p-1 mb-3"
					style={{
						display: "grid",
						gridTemplateColumns: "1fr 1fr 1fr 1fr",
					}}
				>
					{product.easy_to_apply == 1 && (
						<div className="d-flex flex-column align-items-center">
							<img src={easy_to_apply_png} alt="" />
							<span
								className="text-muted text-break text-center mt-1"
								style={{ fontSize: "14px" }}
							>
								{t("easy_to_apply")}
							</span>
						</div>
					)}
					{product.eco_friendly == 1 && (
						<div className="d-flex flex-column align-items-center">
							<img src={eco_friendly_png} alt="" />
							<span
								className="text-muted text-break text-center mt-1"
								style={{ fontSize: "14px" }}
							>
								{t("eco_friendly")}
							</span>
						</div>
					)}
					{product.excellent_adhesion == 1 && (
						<div className="d-flex flex-column align-items-center">
							<img src={excellent_adhesion_png} alt="" />
							<span
								className="text-muted text-break text-center mt-1"
								style={{ fontSize: "14px" }}
							>
								{t("excellent_adhesion")}
							</span>
						</div>
					)}
					{product.excellent_washability == 1 && (
						<div className="d-flex flex-column align-items-center">
							<img src={excellent_washability_png} alt="" />
							<span
								className="text-muted text-break text-center mt-1"
								style={{ fontSize: "14px" }}
							>
								{t("excellent_washability")}
							</span>
						</div>
					)}
					{product.high_durability == 1 && (
						<div className="d-flex flex-column align-items-center">
							<img src={high_durability_png} alt="" />
							<span
								className="text-muted text-break text-center mt-1"
								style={{ fontSize: "14px" }}
							>
								{t("high_durability")}
							</span>
						</div>
					)}
					{product.multiple_colors == 1 && (
						<div className="d-flex flex-column align-items-center">
							<img src={multiple_colors_png} alt="" />
							<span
								className="text-muted text-break text-center mt-1"
								style={{ fontSize: "14px" }}
							>
								{t("multiple_colors")}
							</span>
						</div>
					)}

					{product.multiple_designs == 1 && (
						<div className="d-flex flex-column align-items-center">
							<img src={multiple_designs_png} alt="" />
							<span
								className="text-muted text-break text-center mt-1"
								style={{ fontSize: "14px" }}
							>
								{t("multiple_designs")}
							</span>
						</div>
					)}
					{product.odorless == 1 && (
						<div className="d-flex flex-column align-items-center">
							<img src={odorless_png} alt="" />
							<span
								className="text-muted text-break text-center mt-1"
								style={{ fontSize: "14px" }}
							>
								{t("odorless")}
							</span>
						</div>
					)}
					{product.resistance_to_efflorescence == 1 && (
						<div className="d-flex flex-column align-items-center">
							<img src={resistance_to_efflorescence_png} alt="" />
							<span
								className="text-muted text-break text-center mt-1"
								style={{ fontSize: "14px" }}
							>
								{t("resistance_to_efflorescence")}
							</span>
						</div>
					)}
					{product.stain_resistant == 1 && (
						<div className="d-flex flex-column align-items-center">
							<img src={stain_resistant_png} alt="" />
							<span
								className="text-muted text-break text-center mt-1"
								style={{ fontSize: "14px" }}
							>
								{t("stain_resistant")}
							</span>
						</div>
					)}
				</div> */}
				<h3 className="mb-3">
					{product.price} {t("sar")}
				</h3>
				<Link to={`/product/${product.id}`} className="btn btn-danger ">
					{t("details")}
				</Link>
			</Card.Body>
		</Card>
	);
}

export default ProductCard;
