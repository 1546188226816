import React from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Link } from "react-router-dom";
// import "leaflet/dist/leaflet.css";

function LandingPageMap({ branches }) {
	const { t } = useTranslation();
	return (
		<div className="map">
			<div className="container">
				<h1 className="display-4 text-center mb-5">
					{t("ourBranches")}
				</h1>
				<div className="text-center mb-5">{t("slide3Description")}</div>
				<MapContainer
					center={[25.285242404482357, 45.99322077113195]}
					zoom={5}
					style={{ height: "500px" }}
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					attributionControl={false}
				>
					<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
					{branches.map((branch) => (
						<Marker
							key={branch.id}
							position={[Number(branch.lat), Number(branch.long)]}
						>
							<Popup>
								<strong> {branch.name} </strong>
							</Popup>
						</Marker>
					))}
				</MapContainer>
				<div className="text-center mt-5">
					<Link to="/branches" className="btn btn-outline-danger">
						{t("ourBranches")}
					</Link>
				</div>
			</div>
		</div>
	);
}

export default LandingPageMap;
