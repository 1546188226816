import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import axios from "axios";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import actionTypes from "../redux/actionTypes";

function Checkout({ setCart }) {
	const { t, i18n } = useTranslation();
	const [isLoading, isLoadingSet] = useState(true);
	const [isSubmitting, isSubmittingSet] = useState(false);
	const [user, userSet] = useState({});
	const [total, totalSet] = useState("");
	const [countItems, countItemsSet] = useState("");
	const [phone, phoneSet] = useState("");
	const [address, addressSet] = useState("");
	const [note, noteSet] = useState("");
	const [errors, errorsSet] = useState([]);
	const history = useHistory();

	useEffect(() => {
		axios.get("/profile").then((result) => {
			userSet(result.data);
			phoneSet(result.data.phone);
			addressSet(result.data.address);
		});
		axios.get("/cart/total").then((result) => {
			totalSet(result.data[0].total);
		});

		axios.get("/cart/count").then((result) => {
			countItemsSet(result.data);
			isLoadingSet(false);
		});
	}, [i18n.language]);

	function handleSubmit() {
		errorsSet([]);
		isSubmittingSet(true);
		axios
			.post("/orders", {
				address: address,
				phone: phone,
				note: note,
			})
			.then((result) => {
				setCart(0);
				swal({
					title: t("requestReceived"),
					text: t("requestReceivedMessage"),
					icon: "success",
					button: false,
				}).then(() => history.push("/home"));
				isSubmittingSet(false);
			})
			.catch((err) => {
				console.log(err.response);
				if (err.response.data.errors) {
					errorsSet(err.response.data.errors);
				}
				isSubmittingSet(false);
			});
	}

	return (
		<Layout>
			{isLoading ? (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-danger"
						style={{
							width: "100px",
							height: "100px",
						}}
						role="status"
					></div>
				</div>
			) : (
				<div className="card">
					<div className="card-body">
						<h1 className="mb-5">{t("checkout")}</h1>
						{countItems === 0 ? (
							<div
								className="mt-5 mb-5 text-center text-muted"
								style={{ fontSize: "25px" }}
							>
								{t("noCartItems")}
							</div>
						) : (
							<>
								<label>{t("fullName")}</label>
								<h4 className="font-weight-normal">
									{user.name}
								</h4>

								<label>{t("total")}</label>
								<h4 className="font-weight-normal">
									{total} {t("sar")}
								</h4>

								<label className="mt-4">{t("phone")}</label>
								<input
									type="text"
									className="form-control"
									value={phone}
									onChange={(e) => phoneSet(e.target.value)}
									placeholder={t("phone")}
								/>
								{errors["phone"] && (
									<p className="text-danger">
										{errors["phone"]}
									</p>
								)}

								<label className="mt-4">{t("address")}</label>
								<input
									type="text"
									className="form-control"
									value={address}
									onChange={(e) => addressSet(e.target.value)}
									placeholder={t("address")}
								/>
								{errors["address"] && (
									<p className="text-danger">
										{errors["address"]}
									</p>
								)}

								<label className="mt-4">
									{t("additionalNotes")}
								</label>
								<textarea
									className="form-control"
									rows="5"
									placeholder={t(
										"additionalNotesPlaceholder"
									)}
									value={note}
									onChange={(e) => noteSet(e.target.value)}
								></textarea>

								<button
									className="btn btn-danger ml-auto mr-auto d-block mt-5 btn-lg"
									disabled={isSubmitting}
									onClick={handleSubmit}
								>
									{isSubmitting && (
										<span
											className="spinner-border spinner-border-sm mr-2 mb-2"
											role="status"
										></span>
									)}
									{t("confirmOrder")}
								</button>
							</>
						)}
					</div>
				</div>
			)}
		</Layout>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		setCart: (cart) => dispatch({ type: actionTypes.setCart, cart: cart }),
	};
}

export default connect(null, mapDispatchToProps)(Checkout);
