import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

function Home() {
	useLayoutEffect(() => window.scroll(0, 0), []);
	const { t, i18n } = useTranslation();
	const [isLoading, isLoadingSet] = useState(true);
	const [orders, ordersSet] = useState([]);

	const [pages, pagesSet] = useState(0);
	const [currentPage, currentPageSet] = useState(1);

	useEffect(() => {
		isLoadingSet(true);
		ordersSet([]);
		axios
			.get("/orders", {
				params: {
					page: currentPage,
				},
			})
			.then((result) => {
				ordersSet(result.data.data);
				console.log(result);
				window.scroll(0, 0);
				pagesSet(result.data.meta.last_page);
				isLoadingSet(false);
			});
	}, [currentPage]);

	return (
		<Layout>
			<h1>{t("myAccount")}</h1>
			<h4 className="mt-5">
				{t("requestSpecialistVisitNow")}{" "}
				<Link to="/visit-request" className="btn btn-danger ml-3 mt-2">
					{" "}
					{t("specialistVisit")}{" "}
				</Link>
			</h4>

			<h2 className="mt-5">{t("orders")}</h2>

			{orders.map((order) => (
				<div key={order.id} className="card mt-5 mb-5">
					<div className="card-body">
						<h5>
							{t("orderNumber")} #{order.id}{" "}
							{order.status === "new" && (
								<span className="badge badge-info">
									{t("new")}
								</span>
							)}
							{order.status === "in_process" && (
								<span className="badge badge-warning">
									{t("inProcess")}
								</span>
							)}
							{order.status === "done" && (
								<span className="badge badge-success">
									{t("delivered")}
								</span>
							)}
							{order.status === "canceled" && (
								<span className="badge badge-danger">
									{t("canceled")}
								</span>
							)}
						</h5>

						<p className="mt-3">
							{t("orderDate")} : {order.created_at.slice(0, 10)}
						</p>

						<p>
							{t("total")} : {order.total} {t("sar")}
						</p>

						<div className="table-responsive mt-5">
							<table className="table text-left">
								<thead>
									<tr>
										<th scope="col">{t("product")}</th>

										<th scope="col">{t("color")}</th>
										<th scope="col">{t("sheenLevel")}</th>
										<th scope="col">{t("size")}</th>
										<th scope="col">{t("price")}</th>
										<th scope="col">{t("amount")}</th>
									</tr>
								</thead>
								<tbody>
									{order?.items?.map((item) => (
										<tr>
											<td>
												{i18n.language === "en"
													? item.product_en
													: item.product_ar}
											</td>
											<td>{item.color}</td>
											<td>
												{i18n.language === "en"
													? item.sheen_en
													: item.sheen_ar}
											</td>
											<td>{item.size}</td>

											<td>
												{" "}
												{item.price} {t("sar")}
											</td>
											<td>x{item.quantity}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>

						{/* <div className="text-right">
							<Link className="btn btn-outline-danger">
								{t("orderDetails")}
							</Link>
						</div> */}
					</div>
				</div>
			))}

			{!isLoading && orders.length < 1 && (
				<>
					<h2
						className="text-muted text-center font-weight-normal"
						style={{ marginTop: "70px" }}
					>
						{t("noOrders")}
					</h2>
					<div className="text-center mt-3">
						<Link to="/shop" className="btn btn-outline-danger">
							{t("shopNow")}
						</Link>
					</div>
				</>
			)}

			{isLoading && (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-danger"
						style={{
							width: "100px",
							height: "100px",
						}}
						role="status"
					></div>
				</div>
			)}
			{!isLoading && (
				<div className="d-flex justify-content-center">
					<Pagination
						count={pages}
						color="secondary"
						shape="rounded"
						page={currentPage}
						onChange={(e, value) => currentPageSet(value)}
						hidePrevButton
						hideNextButton
					/>
				</div>
			)}
		</Layout>
	);
}

export default Home;
