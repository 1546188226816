import React, { useState, useEffect } from "react";
import Rating from "@material-ui/lab/Rating";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Link } from "react-router-dom";

function CartItem({ item, refreshCart }) {
	const [isDeleting, isDeletingSet] = useState(false);
	const [isEditing, isEditingSet] = useState(false);
	const [productQuantity, productQuantitySet] = useState(item.quantity);
	const [productPrice, productPriceSet] = useState();
	const [productColor, productColorSet] = useState(item?.color_id);
	const [productSize, productSizeSet] = useState(item?.size_id);
	const [productSheen, productSheenSet] = useState(item?.sheen_id);

	const [prices, pricesSet] = useState(item?.product.prices);
	const [sheenOptions, sheenOptionsSet] = useState([]);
	const [sizeOptions, sizeOptionsSet] = useState([]);
	const [selectedPrice, selectedPriceSet] = useState("");
	const { t, i18n } = useTranslation();

	useEffect(() => {
		console.log(item);
		productColorSet(item.color_id);
		productSheenSet(item.sheen_id);
		productSizeSet(item.size_id);
	}, []);

	useEffect(() => {
		if (item?.product?.sheens?.length > 0) {
			let newArr = prices.filter((i) => i.color_id == productColor);
			let uniqueIds = [];
			for (let i = 0; i < newArr.length; i++) {
				if (!uniqueIds.includes(newArr[i].sheen_id)) {
					uniqueIds.push(newArr[i].sheen_id);
				}
			}
			let uniqueArr = [];
			let sheens = item.product.sheens;
			for (let i = 0; i < uniqueIds.length; i++) {
				uniqueArr.push(
					sheens.filter((x) => x.sheen_id == uniqueIds[i])[0]
				);
			}
			sheenOptionsSet(uniqueArr);
			// productSheenSet(uniqueIds[0]);
		}
	}, [productColor]);

	useEffect(() => {
		if (item?.product?.sizes?.length > 0) {
			let newArr = prices.filter((i) => i.color_id == productColor);

			newArr = newArr.filter((i) => i.sheen_id == productSheen);
			let uniqueIds = [];
			for (let i = 0; i < newArr.length; i++) {
				if (!uniqueIds.includes(newArr[i].size_id)) {
					uniqueIds.push(newArr[i].size_id);
				}
			}
			let uniqueArr = [];
			let sizes = item.product.sizes;
			for (let i = 0; i < uniqueIds.length; i++) {
				uniqueArr.push(
					sizes.filter((x) => x.size_id == uniqueIds[i])[0]
				);
			}
			sizeOptionsSet(uniqueArr);
			// productSizeSet(uniqueArr[0]?.size_id);
		}
	}, [productSheen, productColor]);

	useEffect(() => {
		let price = prices;
		if (productSize) {
			price = price.filter((i) => i.size_id == productSize);
		}
		if (productSheen) {
			price = price.filter((i) => i.sheen_id == productSheen);
		}

		if (productColor) {
			price = price.filter((i) => i.color_id == productColor);
		}

		selectedPriceSet(price[0]?.id);

		if (productQuantity === "" || productQuantity === 0) {
			productQuantitySet(1);
		}
		try {
			productPriceSet(Number(price[0]?.price) * productQuantity);
		} catch {
			productPriceSet(0);
		}
	}, [productSize, productQuantity, productSheen, productColor]);

	useEffect(() => {
		let price = prices;
		if (productSize) {
			price = price.filter((i) => i.size_id == productSize);
		}
		if (productSheen) {
			price = price.filter((i) => i.sheen_id == productSheen);
		}

		if (productColor) {
			price = price.filter((i) => i.color_id == productColor);
		}
		selectedPriceSet(price[0]?.id);

		if (productQuantity === "" || productQuantity === 0) {
			productQuantitySet(1);
		}
		try {
			productPriceSet(Number(price[0]?.price) * productQuantity);
		} catch {
			productPriceSet(0);
		}
	}, [productSize, productQuantity, productSheen, productColor]);

	// Edit
	function handleEdit() {
		isEditingSet(true);
		axios
			.put(`/cart/${item.id}`, {
				item_id: selectedPrice,
				quantity: productQuantity,
			})
			.then(() => {
				isEditingSet(false);
				refreshCart();
			})
			.catch(() => {
				isEditingSet(false);
			});
	}

	function handleDelete() {
		isDeletingSet(true);
		axios
			.delete(`/cart/${item.id}`)
			.then(() => {
				isDeletingSet(false);
				refreshCart();
			})
			.catch(() => {
				isDeletingSet(false);
			});
	}

	useEffect(() => console.log(productColor), [productColor]);

	return (
		<div className="card shadow-sm mt-5 mb-5">
			<div className="card-body">
				<div className="row">
					<div className="col-md-4 col-xs-12">
						<img
							src={item.product.image}
							alt=""
							className="w-100 rounded"
							style={{ maxHeight: "200px", objectFit: "cover" }}
						/>
					</div>
					<div className="col-md-8 col-xs-12">
						<Link
							to={`/product/${item.product.id}`}
							style={{ textDecoration: "none", color: "black" }}
						>
							<h5 className="mt-2 card-title">
								{item.product.name}
							</h5>
						</Link>
						<Rating value={item.product?.rate} readOnly />

						<p className="card-text">
							<div className="row">
								{item.product?.colors?.length > 0 && (
									<div className="col-md-6 col-xs-12">
										<label className="d-block">
											{t("chooseColor")}
										</label>
										<FormControl
											variant="outlined"
											fullWidth
										>
											<Select
												value={productColor}
												onChange={(e) =>
													productColorSet(
														e.target.value
													)
												}
											>
												{item.product?.colors?.map(
													(item) => (
														<MenuItem
															key={item.color_id}
															value={
																item.color_id
															}
														>
															{item.color_id ==
															null ? (
																"-"
															) : (
																<div className="d-flex align-items-center">
																	<div
																		className="mr-3"
																		style={{
																			width: "40px",
																			height: "40px",
																			backgroundColor:
																				item
																					.color
																					.value,
																		}}
																	></div>
																	<div>
																		{
																			item
																				.color
																				.name
																		}
																	</div>
																</div>
															)}
														</MenuItem>
													)
												)}

												{}
											</Select>
										</FormControl>
									</div>
								)}
								<div className="col-md-6 col-xs-12">
									<label className="d-block">
										{t("chooseSheen")}
									</label>
									<FormControl variant="outlined" fullWidth>
										<Select
											value={productSheen}
											onChange={(e) =>
												productSheenSet(e.target.value)
											}
										>
											{sheenOptions?.map((sheen) => (
												<MenuItem
													key={sheen.sheen_id}
													value={sheen.sheen_id}
												>
													{sheen.sheen == null
														? "-"
														: sheen.sheen[
																"name_" +
																	i18n.language
														  ]}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
								<div className="col-md-6 col-xs-12">
									<label className="d-block">
										{t("chooseSize")}
									</label>
									<FormControl variant="outlined" fullWidth>
										<Select
											value={productSize}
											onChange={(e) =>
												productSizeSet(e.target.value)
											}
										>
											{sizeOptions?.map((size) => (
												<MenuItem
													key={size.size_id}
													value={size.size_id}
												>
													{size.size_id == null
														? "-"
														: size.size[
																"name_" +
																	i18n.language
														  ]}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-xs-12">
									<label className="d-block">
										{t("quantity")}
									</label>
									<input
										type="number"
										placeholder={t("quantity")}
										className="form-control"
										min="1"
										step="1"
										value={productQuantity}
										onChange={(e) =>
											productQuantitySet(
												parseInt(e.target.value)
											)
										}
									/>
								</div>
								<div className="col-md-6 col-xs-12">
									<label className="d-block mt-3">
										{t("totalPrice")}
									</label>

									<h3>
										{productPrice || "0"} {t("sar")}
									</h3>
								</div>
							</div>
							<div className="d-flex flex-row-reverse">
								<button
									className="btn btn-outline-danger ml-1"
									disabled={isDeleting}
									onClick={handleDelete}
								>
									{isDeleting && (
										<span
											className="spinner-border spinner-border-sm mr-2 mb-1"
											role="status"
										></span>
									)}
									{t("delete")}
								</button>

								<button
									className="btn btn-primary"
									disabled={isEditing || !productPrice}
									onClick={handleEdit}
								>
									{isEditing && (
										<span
											className="spinner-border spinner-border-sm mr-2 mb-1"
											role="status"
										></span>
									)}
									{t("save")}
								</button>
							</div>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CartItem;
