import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { BiSearchAlt2 } from "react-icons/bi";
import { useHistory } from "react-router-dom";

function NavbarCustom({ show }) {
	const { t } = useTranslation();
	const history = useHistory();
	const [search, searchSet] = useState();

	function handleSearch(e) {
		e.preventDefault();
		if (search) {
			history.push(`/shop?search=${search}`);
			searchSet("");
		}
	}

	return (
		<div
			className="navbar__custom d-none d-lg-block"
			style={{ top: show ? "53px" : "71px", position: "sticky" }}
		>
			<div className="container d-flex justify-content-between align-items-center">
				<div className="d-flex p-2">
					<NavLink exact to="/" className="navbar__item">
						{t("home")}
					</NavLink>
					<NavLink exact to="/shop" className="navbar__item">
						{t("shop")}
					</NavLink>
					<NavLink exact to="/gallery" className="navbar__item">
						{t("gallery")}
					</NavLink>
					<NavLink exact to="/visit-request" className="navbar__item">
						{t("ourServices")}
					</NavLink>
					<NavLink exact to="/branches" className="navbar__item">
						{t("ourBranches")}
					</NavLink>
					<HashLink smooth to="/#about" className="navbar__item">
						{t("aboutCompany")}
					</HashLink>
					<HashLink smooth to="/#contact-us" className="navbar__item">
						{t("contactUs")}
					</HashLink>
				</div>
				<form onSubmit={handleSearch}>
					<div className="input-group">
						<input
							className="form-control flex-fill border-right-0"
							type="search"
							value={search}
							onChange={(e) => searchSet(e.target.value)}
							placeholder={t("search")}
							aria-label="Search"
						/>
						<div className="input-group-append">
							<button className="btn btn-danger" type="submit">
								{/* {t("search")} */}
								<BiSearchAlt2 style={{ fontSize: "20px" }} />
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default NavbarCustom;
