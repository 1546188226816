import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Rating from "@material-ui/lab/Rating";
import productTitleImage from "../assets/product-title-background.png";
import Carousel from "react-bootstrap/Carousel";

import easy_to_apply_png from "../assets/product-icons/easy-to-apply.png";
import eco_friendly_png from "../assets/product-icons/eco-friendly.png";
import excellent_adhesion_png from "../assets/product-icons/excellent-adhesion.png";
import excellent_washability_png from "../assets/product-icons/excellent-washability.png";
import high_durability_png from "../assets/product-icons/high-durability.png";
import multiple_colors_png from "../assets/product-icons/multiple-colors.png";
import multiple_designs_png from "../assets/product-icons/multiple-designs.png";
import odorless_png from "../assets/product-icons/odorless.png";
import resistance_to_efflorescence_png from "../assets/product-icons/resistance-to-efflorescence.png";
import stain_resistant_png from "../assets/product-icons/stain-resistant.png";

function Product({ user }) {
	useLayoutEffect(() => window.scroll(0, 0), []);
	const [product, productSet] = useState();
	const [isLoading, isLoadingSet] = useState(false);
	const [isSubmitting, isSubmittingSet] = useState(false);
	const { productId } = useParams();
	const { t, i18n } = useTranslation();
	const [productSize, productSizeSet] = useState("");
	const [productSheen, productSheenSet] = useState("");
	const [productQuantity, productQuantitySet] = useState(1);
	const [productPrice, productPriceSet] = useState();
	const [productColor, productColorSet] = useState();
	const [prices, pricesSet] = useState([]);
	const [sheenOptions, sheenOptionsSet] = useState([]);
	const [sizeOptions, sizeOptionsSet] = useState([]);
	const [selectedPrice, selectedPriceSet] = useState("");
	const history = useHistory();

	useEffect(() => {
		isLoadingSet(true);
		setTimeout(() => {
			axios.get(`/products/${productId}`).then((result) => {
				productSet(result.data.data);
				console.log(result.data.data);
				pricesSet(result.data.data.prices);
				selectedPriceSet(result.data.data.prices[0]);
				if (result.data.data.prices.length > 0) {
					productColorSet(result.data.data.prices[0].color_id);
					productSheenSet(result.data.data.prices[0].sheen_id);
					productSizeSet(result.data.data.prices[0].size_id);
				}
				isLoadingSet(false);
			});
		}, 100);
	}, [i18n.language]);

	useEffect(() => {
		if (product?.sheens?.length > 0) {
			let newArr = prices.filter((i) => i.color_id == productColor);
			let uniqueIds = [];
			for (let i = 0; i < newArr.length; i++) {
				if (!uniqueIds.includes(newArr[i].sheen_id)) {
					uniqueIds.push(newArr[i].sheen_id);
				}
			}
			let uniqueArr = [];
			let sheens = product.sheens;
			for (let i = 0; i < uniqueIds.length; i++) {
				uniqueArr.push(
					sheens.filter((x) => x.sheen_id == uniqueIds[i])[0]
				);
			}
			sheenOptionsSet(uniqueArr);
			productSheenSet(uniqueIds[0]);
		}
	}, [productColor]);

	useEffect(() => {
		if (product?.sizes?.length > 0) {
			let newArr = prices.filter((i) => i.color_id == productColor);

			newArr = newArr.filter((i) => i.sheen_id == productSheen);
			console.log(newArr);
			let uniqueIds = [];
			for (let i = 0; i < newArr.length; i++) {
				if (!uniqueIds.includes(newArr[i].size_id)) {
					uniqueIds.push(newArr[i].size_id);
				}
			}
			console.log(uniqueIds);
			let uniqueArr = [];
			let sizes = product.sizes;
			for (let i = 0; i < uniqueIds.length; i++) {
				uniqueArr.push(
					sizes.filter((x) => x.size_id == uniqueIds[i])[0]
				);
			}
			sizeOptionsSet(uniqueArr);
			console.log(uniqueArr);
			productSizeSet(uniqueArr[0]?.size_id);
		}
	}, [productSheen, productColor]);

	useEffect(() => {
		let price = prices;
		if (productSize) {
			price = price.filter((i) => i.size_id == productSize);
		}
		if (productSheen) {
			price = price.filter((i) => i.sheen_id == productSheen);
		}

		if (productColor) {
			price = price.filter((i) => i.color_id == productColor);
		}

		console.log(price);
		selectedPriceSet(price[0]?.id);

		if (productQuantity === "" || productQuantity === 0) {
			productQuantitySet(1);
		}
		try {
			productPriceSet(Number(price[0]?.price) * productQuantity);
		} catch {
			productPriceSet(0);
		}
	}, [productSize, productQuantity, productSheen, productColor]);

	function handleSubmit(e) {
		e.preventDefault();
		isSubmittingSet(true);
		axios
			.post("/cart", {
				item_id: selectedPrice,
				quantity: productQuantity,
			})
			.then((result) => {
				isSubmittingSet(false);
				history.push("/cart");
			})
			.catch((err) => console.log(err.response));
	}

	return (
		<>
			{!isLoading && (
				<div
					style={{
						backgroundImage: `url(${productTitleImage})`,
						fontSize: "25px",
						color: "white",
						padding: "15px",
						paddingTop: "60px",
					}}
				>
					<h1 className="display-4 mb-5 container">
						{product?.name}
					</h1>
				</div>
			)}
			<div className="container mt-5 mb-5">
				{isLoading ? (
					<div className="d-flex justify-content-center mt-5">
						<div
							className="spinner-border text-danger"
							style={{
								width: "100px",
								height: "100px",
							}}
							role="status"
						></div>
					</div>
				) : (
					<>
						<div className="row mt-3">
							<div className="col-md-8 col-xs-12 mb-3">
								<Carousel touch={false} className="mb-5">
									<Carousel.Item interval={5000}>
										<img
											className="d-block w-100"
											src={product?.image}
											alt="First slide"
											style={{
												height: "400px",
												objectPosition: "50% 50%",
												objectFit: "cover",
											}}
										/>
									</Carousel.Item>
									{product?.images.map((item) => (
										<Carousel.Item interval={5000}>
											<img
												className="d-block w-100"
												src={item?.image}
												alt="First slide"
												style={{
													height: "400px",
													objectPosition: "50% 50%",
													objectFit: "cover",
												}}
											/>
										</Carousel.Item>
									))}
								</Carousel>
								<Rating value={product?.rate} readOnly />
								<h3 className="font-weight-light mb-3 mt-3">
									{t("description")}
								</h3>
								<p className="mb-5">{product?.description}</p>
								{/* <div
									className="p-1 mb-3"
									style={{
										display: "grid",
										gridTemplateColumns: "1fr 1fr 1fr 1fr",
									}}
								>
									{product?.easy_to_apply == 1 && (
										<div className="d-flex flex-column align-items-center">
											<img
												src={easy_to_apply_png}
												alt=""
											/>
											<span
												className="text-muted text-break text-center mt-1"
												style={{ fontSize: "14px" }}
											>
												{t("easy_to_apply")}
											</span>
										</div>
									)}
									{product?.eco_friendly == 1 && (
										<div className="d-flex flex-column align-items-center">
											<img
												src={eco_friendly_png}
												alt=""
											/>
											<span
												className="text-muted text-break text-center mt-1"
												style={{ fontSize: "14px" }}
											>
												{t("eco_friendly")}
											</span>
										</div>
									)}
									{product?.excellent_adhesion == 1 && (
										<div className="d-flex flex-column align-items-center">
											<img
												src={excellent_adhesion_png}
												alt=""
											/>
											<span
												className="text-muted text-break text-center mt-1"
												style={{ fontSize: "14px" }}
											>
												{t("excellent_adhesion")}
											</span>
										</div>
									)}
									{product?.excellent_washability == 1 && (
										<div className="d-flex flex-column align-items-center">
											<img
												src={excellent_washability_png}
												alt=""
											/>
											<span
												className="text-muted text-break text-center mt-1"
												style={{ fontSize: "14px" }}
											>
												{t("excellent_washability")}
											</span>
										</div>
									)}
									{product?.high_durability == 1 && (
										<div className="d-flex flex-column align-items-center">
											<img
												src={high_durability_png}
												alt=""
											/>
											<span
												className="text-muted text-break text-center mt-1"
												style={{ fontSize: "14px" }}
											>
												{t("high_durability")}
											</span>
										</div>
									)}
									{product?.multiple_colors == 1 && (
										<div className="d-flex flex-column align-items-center">
											<img
												src={multiple_colors_png}
												alt=""
											/>
											<span
												className="text-muted text-break text-center mt-1"
												style={{ fontSize: "14px" }}
											>
												{t("multiple_colors")}
											</span>
										</div>
									)}

									{product?.multiple_designs == 1 && (
										<div className="d-flex flex-column align-items-center">
											<img
												src={multiple_designs_png}
												alt=""
											/>
											<span
												className="text-muted text-break text-center mt-1"
												style={{ fontSize: "14px" }}
											>
												{t("multiple_designs")}
											</span>
										</div>
									)}
									{product?.odorless == 1 && (
										<div className="d-flex flex-column align-items-center">
											<img src={odorless_png} alt="" />
											<span
												className="text-muted text-break text-center mt-1"
												style={{ fontSize: "14px" }}
											>
												{t("odorless")}
											</span>
										</div>
									)}
									{product?.resistance_to_efflorescence ===
										1 && (
										<div className="d-flex flex-column align-items-center">
											<img
												src={
													resistance_to_efflorescence_png
												}
												alt=""
											/>
											<span
												className="text-muted text-break text-center mt-1"
												style={{ fontSize: "14px" }}
											>
												{t(
													"resistance_to_efflorescence"
												)}
											</span>
										</div>
									)}
									{product?.stain_resistant == 1 && (
										<div className="d-flex flex-column align-items-center">
											<img
												src={stain_resistant_png}
												alt=""
											/>
											<span
												className="text-muted text-break text-center mt-1"
												style={{ fontSize: "14px" }}
											>
												{t("stain_resistant")}
											</span>
										</div>
									)}
								</div> */}
								{product?.file && (
									<a
										href={product?.file}
										className="btn btn-success btn-lg mt-4 mb-5"
									>
										{t("downloadFile")}
									</a>
								)}
								<h3 className="font-weight-light mb-3">
									{t("productFeatures")}
								</h3>

								<table className="table mt-5">
									<tbody>
										<tr>
											<td>{t("category")}</td>
											<td>
												<Link
													to={`/shop/categories/${product?.category?.id}`}
												>
													{product?.category?.name}
												</Link>
											</td>
										</tr>
										{product?.features?.map((feature) => (
											<tr>
												<td>
													{
														feature[
															"name_" +
																i18n.language
														]
													}
												</td>
												<td>
													{
														feature[
															"value_" +
																i18n.language
														]
													}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<div className="col-md-4 col-xs-12">
								<div className="card">
									<form onSubmit={handleSubmit}>
										<div className="card-body">
											<h5 className="card-title">
												{t("addToCart")}
											</h5>
											<p className="card-text">
												{product?.colors?.length >
													0 && (
													<>
														<label className="d-block">
															{t("chooseColor")}
														</label>
														<FormControl
															variant="outlined"
															fullWidth
														>
															<Select
																value={
																	productColor
																}
																onChange={(e) =>
																	productColorSet(
																		e.target
																			.value
																	)
																}
															>
																{product?.colors?.map(
																	(item) => (
																		<MenuItem
																			key={
																				item.color_id
																			}
																			value={
																				item.color_id
																			}
																		>
																			{item.color_id ==
																			null ? (
																				"-"
																			) : (
																				<div className="d-flex align-items-center">
																					<div
																						className="mr-3"
																						style={{
																							width: "40px",
																							height: "40px",
																							backgroundColor:
																								item
																									.color
																									.value,
																						}}
																					></div>
																					<div>
																						{
																							item
																								.color
																								.name
																						}
																					</div>
																				</div>
																			)}
																		</MenuItem>
																	)
																)}

																{}
															</Select>
														</FormControl>
													</>
												)}

												{product?.sheens?.length >
													0 && (
													<>
														<label className="d-block">
															{t("chooseSheen")}
														</label>
														<FormControl
															variant="outlined"
															fullWidth
														>
															<Select
																value={
																	productSheen
																}
																onChange={(e) =>
																	productSheenSet(
																		e.target
																			.value
																	)
																}
															>
																{sheenOptions?.map(
																	(sheen) => (
																		<MenuItem
																			key={
																				sheen.sheen_id
																			}
																			value={
																				sheen.sheen_id
																			}
																		>
																			{sheen.sheen ==
																			null
																				? "-"
																				: sheen
																						.sheen[
																						"name_" +
																							i18n.language
																				  ]}
																		</MenuItem>
																	)
																)}
															</Select>
														</FormControl>
													</>
												)}
												{product?.sizes?.length > 0 && (
													<>
														<label className="d-block">
															{t("chooseSize")}
														</label>
														<FormControl
															variant="outlined"
															fullWidth
														>
															<Select
																value={
																	productSize
																}
																onChange={(e) =>
																	productSizeSet(
																		e.target
																			.value
																	)
																}
															>
																{sizeOptions?.map(
																	(size) => (
																		<MenuItem
																			key={
																				size.size_id
																			}
																			value={
																				size.size_id
																			}
																		>
																			{size.size_id ==
																			null
																				? "-"
																				: size
																						.size[
																						"name_" +
																							i18n.language
																				  ]}
																		</MenuItem>
																	)
																)}
															</Select>
														</FormControl>
													</>
												)}
												<label className="d-block">
													{t("quantity")}
												</label>
												<input
													type="number"
													placeholder={t("quantity")}
													className="form-control"
													min="1"
													step="1"
													value={productQuantity}
													onChange={(e) =>
														productQuantitySet(
															parseInt(
																e.target.value
															)
														)
													}
												/>
												<label className="d-block mt-3">
													{t("totalPrice")}
												</label>

												<h3>
													{productPrice || "0"}{" "}
													{t("sar")}
												</h3>
											</p>

											{user ? (
												<button
													className="btn btn-danger btn-block"
													disabled={
														!productPrice ||
														isSubmitting
													}
													type="submit"
												>
													{isSubmitting && (
														<span
															className="spinner-border spinner-border-sm mr-2 mb-1"
															role="status"
														></span>
													)}
													{t("addToCart")}
												</button>
											) : (
												<Link
													to="/login"
													className="btn btn-danger btn-block"
												>
													{t("signInToOrder")}
												</Link>
											)}
										</div>
									</form>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}

function mapStateToProps(state) {
	return {
		user: state.user,
	};
}

export default connect(mapStateToProps)(Product);
