import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import axios from "axios";

function ContactUs() {
	const { t, i18n } = useTranslation();
	const [name, nameSet] = useState("");
	const [email, emailSet] = useState("");
	const [phone, phoneSet] = useState("");
	const [message, messageSet] = useState("");
	const [isSubmitting, isSubmittingSet] = useState(false);
	const [errors, errorsSet] = useState([]);

	function handleSubmit(e) {
		e.preventDefault();
		errorsSet([]);
		isSubmittingSet(true);
		axios
			.post("/messages", {
				name: name,
				email: email,
				phone: phone,
				message: message,
			})
			.then(() => {
				isSubmittingSet(false);
				nameSet("");
				emailSet("");
				phoneSet("");
				messageSet("");
				swal({
					title: t("thankYou"),
					text: t("messageAlert"),
					icon: "success",
					button: false,
				});
			})
			.catch((err) => {
				console.log(err.response);
				errorsSet(err?.response?.data?.errors);
				isSubmittingSet(false);
			});
	}

	return (
		<div
			className="container mb-5 d-flex flex-column align-items-center"
			id="contact-us"
		>
			<h1 className="text-center display-4 mb-5 ">{t("contactUs")}</h1>
			<div style={{ width: "40%", minWidth: "300px" }}>
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<p className="text-left text-secondary">
							{t("fullName")}
						</p>
						<input
							className="form-control p-1"
							value={name}
							onChange={(e) => nameSet(e.target.value)}
						/>
						{errors["name"] && (
							<p className="text-danger">{errors["name"]}</p>
						)}
					</div>
					<div className="form-group">
						<p className="text-left text-secondary">{t("email")}</p>
						<input
							className="form-control p-1"
							value={email}
							onChange={(e) => emailSet(e.target.value)}
						/>
						{errors["email"] && (
							<p className="text-danger">{errors["email"]}</p>
						)}
					</div>
					<div className="form-group">
						<p className="text-left text-secondary">
							{t("phoneNumber")}
						</p>
						<input
							className="form-control p-1"
							value={phone}
							onChange={(e) => phoneSet(e.target.value)}
						/>
						{errors["phone"] && (
							<p className="text-danger">{errors["phone"]}</p>
						)}
					</div>
					<div className="form-group">
						<p className="text-left text-secondary">
							{t("message")}
						</p>
						<textarea
							className="form-control p-1"
							rows="5"
							value={message}
							onChange={(e) => messageSet(e.target.value)}
						></textarea>
						{errors["message"] && (
							<p className="text-danger">{errors["message"]}</p>
						)}
					</div>

					<button
						className="btn btn-danger mb-4 d-block ml-auto mr-auto"
						type="submit"
						disabled={isSubmitting}
					>
						{isSubmitting && (
							<span
								className="spinner-border spinner-border-sm mr-2"
								role="status"
							></span>
						)}
						{t("send")}
					</button>
				</form>
			</div>
		</div>
	);
}

export default ContactUs;
