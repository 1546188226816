import React from "react";
import Sidebar from "./Sidebar";

function Layout({ children }) {
	return (
		<div className="container mt-5">
			<div className="row mt-5">
				<Sidebar />
				<div className="col-lg-9 mb-5">{children}</div>
			</div>
		</div>
	);
}

export default Layout;
