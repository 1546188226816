import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import axios from "axios";
import swal from "sweetalert";

function Settings() {
	useLayoutEffect(() => window.scroll(0, 0), []);
	const { t } = useTranslation();
	const [user, userSet] = useState({});
	const [isLoading, isLoadingSet] = useState(true);
	const [isUpdating, isUpdatingSet] = useState(false);
	const [isChangingPassword, isChangingPasswordSet] = useState(false);
	const [updateErrors, updateErrorsSet] = useState([]);
	const [changePasswordErrors, changePasswordErrorsSet] = useState([]);
	const [name, nameSet] = useState("");
	const [address, addressSet] = useState("");
	const [phone, phoneSet] = useState("");
	const [currentPassword, currentPasswordSet] = useState("");
	const [newPassword, newPasswordSet] = useState("");
	const [confirmPassword, confirmPasswordSet] = useState("");

	useEffect(() => {
		axios.get("/profile").then((result) => {
			userSet(result.data);
			nameSet(result.data.name);
			phoneSet(result.data.phone);
			addressSet(result.data.address);
			isLoadingSet(false);
		});
	}, []);

	function handleUpdate(e) {
		e.preventDefault();
		updateErrorsSet([]);
		isUpdatingSet(true);
		axios
			.put("/profile", {
				address: address,
				phone: phone,
				name: name,
			})
			.then(() => {
				isUpdatingSet(false);
				swal({
					title: t("dataSaved"),
					text: t("dataSavedMessage"),
					icon: "success",
					button: false,
				});
			})
			.catch((err) => {
				isUpdatingSet(false);
				updateErrorsSet(err.response.data.errors);
			});
	}

	function handleChangePassword(e) {
		e.preventDefault();
		changePasswordErrorsSet([]);
		isChangingPasswordSet(true);
		axios
			.put("/profile/change-password", {
				password: newPassword,
				current_password: currentPassword,
				password_confirmation: confirmPassword,
			})
			.then(() => {
				isChangingPasswordSet(false);
				newPasswordSet("");
				confirmPasswordSet("");
				currentPasswordSet("");
				swal({
					title: t("passwordChanged"),
					text: t("passwordChangedMessage"),
					icon: "success",
					button: false,
				});
			})
			.catch((err) => {
				isChangingPasswordSet(false);
				changePasswordErrorsSet(err.response.data.errors);
			});
	}

	return (
		<Layout>
			<h1 className="mb-5">{t("settings")}</h1>

			{isLoading ? (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-danger"
						style={{
							width: "100px",
							height: "100px",
						}}
						role="status"
					></div>
				</div>
			) : (
				<div className="mb-5">
					<div className="card">
						<form onSubmit={handleUpdate}>
							<div className="card-body">
								<h4 className="card-title">
									{t("userInformations")}
								</h4>

								<label className="mt-4">{t("fullName")}</label>
								<input
									type="text"
									className="form-control"
									value={name}
									onChange={(e) => nameSet(e.target.value)}
								/>
								{updateErrors["name"] && (
									<p className="text-danger">
										{updateErrors["name"]}
									</p>
								)}

								<label className="mt-3">{t("email")}</label>
								<input
									type="text"
									className="form-control"
									value={user?.email}
									disabled
								/>

								<label className="mt-3">{t("phone")}</label>
								<input
									type="text"
									className="form-control"
									value={phone}
									onChange={(e) => phoneSet(e.target.value)}
								/>
								{updateErrors["phone"] && (
									<p className="text-danger">
										{updateErrors["phone"]}
									</p>
								)}

								<label className="mt-3">{t("address")}</label>
								<textarea
									className="form-control"
									rows="3"
									value={address}
									onChange={(e) => addressSet(e.target.value)}
								></textarea>
								{updateErrors["address"] && (
									<p className="text-danger">
										{updateErrors["address"]}
									</p>
								)}

								<button
									className="btn btn-danger mt-5 d-block ml-auto"
									disabled={isUpdating}
									type="submit"
								>
									{isUpdating && (
										<span
											className="spinner-border spinner-border-sm mr-2 mb-1"
											role="status"
										></span>
									)}
									{t("saveChanges")}
								</button>
							</div>
						</form>
					</div>

					<div className="card mt-5">
						<form onSubmit={handleChangePassword}>
							<div className="card-body">
								<h4 className="card-title">
									{t("changePassword")}
								</h4>

								<label className="mt-4">
									{t("currentPassword")}
								</label>
								<input
									className="form-control"
									value={currentPassword}
									type="password"
									onChange={(e) =>
										currentPasswordSet(e.target.value)
									}
								/>

								{changePasswordErrors["current_password"] && (
									<p className="text-danger">
										{
											changePasswordErrors[
												"current_password"
											]
										}
									</p>
								)}

								<label className="mt-3">
									{t("newPassword")}
								</label>
								<input
									className="form-control"
									value={newPassword}
									type="password"
									onChange={(e) =>
										newPasswordSet(e.target.value)
									}
								/>

								{changePasswordErrors["password"] && (
									<p className="text-danger">
										{changePasswordErrors["password"]}
									</p>
								)}

								<label className="mt-3">
									{t("confirmPassword")}
								</label>
								<input
									className="form-control"
									type="password"
									value={confirmPassword}
									onChange={(e) =>
										confirmPasswordSet(e.target.value)
									}
								/>

								{changePasswordErrors[
									"password_confirmation"
								] && (
									<p className="text-danger">
										{
											changePasswordErrors[
												"password_confirmation"
											]
										}
									</p>
								)}

								<button
									className="btn btn-danger mt-5 d-block ml-auto"
									disabled={isChangingPassword}
									type="submit"
								>
									{isChangingPassword && (
										<span
											className="spinner-border spinner-border-sm mr-2 mb-1"
											role="status"
										></span>
									)}
									{t("changePassword")}
								</button>
							</div>
						</form>
					</div>
				</div>
			)}
		</Layout>
	);
}

export default Settings;
