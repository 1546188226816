import React, { useState } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import { IoGridOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { AiOutlineShoppingCart, AiOutlineSetting } from "react-icons/ai";
import { BsHouseDoor } from "react-icons/bs";
import { BiDoorOpen } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import actionTypes from "../redux/actionTypes";

function Sidebar({ setUser }) {
	const { t } = useTranslation();
	const [logoutModal, logoutModalSet] = useState(false);
	const history = useHistory();

	function handleClose() {
		logoutModalSet(false);
	}

	function handleLogout() {
		localStorage.removeItem("token");
		setUser(false);
		history.push("/");
	}
	return (
		<>
			<div className="col-lg-3 d-none d-lg-block">
				<ul className="list-group shadow m-2 border-0 pb-3">
					<li className="list-group-item border-0">
						<NavLink to="/home" className="sidebar__link text-left">
							<IoGridOutline className="nav-icon mr-2" />
							{t("myAccount")}
						</NavLink>
					</li>
					<li className="list-group-item border-0">
						<NavLink to="/cart" className="sidebar__link text-left">
							<AiOutlineShoppingCart className="nav-icon mr-2" />
							{t("cart")}
						</NavLink>
					</li>
					<li className="list-group-item border-0">
						<NavLink
							to="/visit-request"
							className="sidebar__link text-left"
						>
							<BsHouseDoor className="nav-icon mr-2" />
							{t("visitRequest")}
						</NavLink>
					</li>

					<li className="list-group-item border-0">
						<NavLink
							to="/settings"
							className="sidebar__link text-left"
						>
							<AiOutlineSetting className="nav-icon mr-2" />
							{t("settings")}
						</NavLink>
					</li>

					<li className="list-group-item border-0">
						<Link
							to="#"
							className="sidebar__link text-left"
							onClick={() => logoutModalSet(true)}
						>
							<BiDoorOpen className="nav-icon mr-2" />
							{t("logout")}
						</Link>
					</li>

					{/* <li className="list-group-item border-0">
					<NavLink to="/catalogs" className="sidebar__link text-left">
						<RiGalleryLine className="nav-icon mr-2" />
						{t("catalogs")}
					</NavLink>
				</li> */}
					{/* <li className="list-group-item border-0">
					<NavLink
						to="/best-products"
						className="sidebar__link text-left"
					>
						<BsBookmark className="nav-icon mr-2" />
						{t("bestProducts")}
					</NavLink>
				</li> */}
				</ul>
			</div>

			<Modal show={logoutModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{t("logout")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{t("logoutMessage")}</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleClose} variant="light">
						{t("close")}
					</Button>
					<Button variant="danger" onClick={handleLogout}>
						{t("logout")}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		setUser: (user) => dispatch({ type: actionTypes.setUser, user: user }),
	};
}

export default connect(null, mapDispatchToProps)(Sidebar);
