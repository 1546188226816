import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./Login";

function ProtectedRoute({ path, Component, user }) {
	return (
		<Route path={path} exact>
			{user ? <Component /> : <Login loginRequired />}
		</Route>
	);
}

function mapStateToProps(state) {
	return {
		user: state.user,
	};
}

export default connect(mapStateToProps)(ProtectedRoute);
