import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

function AuthRoute({ path, Component, user }) {
	return (
		<Route path={path} exact>
			{user ? <Redirect to="/home" /> : <Component />}
		</Route>
	);
}

function mapStateToProps(state) {
	return {
		user: state.user,
	};
}

export default connect(mapStateToProps)(AuthRoute);
