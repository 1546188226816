import React, { useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BiStore } from "react-icons/bi";
import { AiOutlinePhone } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

function Branches({ branches }) {
	useLayoutEffect(() => window.scroll(0, 0), []);
	const { t, i18n } = useTranslation();
	// const [branches, branchesSet] = useState([]);
	const [selectedBranch, selectedBranchSet] = useState("");
	const [selectedBranchResult, selectedBranchResultSet] = useState("");
	const [areas, areasSet] = useState([]);
	const [area, areaSet] = useState("");
	const [cities, citiesSet] = useState([]);
	const [city, citySet] = useState("");
	const [filteredCities, filteredCitiesSet] = useState([]);
	const [filteredBranches, filteredBranchesSet] = useState([]);
	const [isLoading, isLoadingSet] = useState(true);
	const [isLoadingBranch, isLoadingBranchSet] = useState(false);

	// useEffect(() => {
	// 	isLoadingSet(true);
	// 	setTimeout(() => {
	// 		axios.get("/branches").then((result) => {
	// 			branchesSet(result.data.data);
	// 			filteredBranchesSet(result.data.data);
	// 			isLoadingSet(false);
	// 		});
	// 	}, 100);
	// }, [i18n.language]);

	useEffect(() => {
		axios
			.get("/areas", {
				headers: {
					lang: i18n.language,
				},
			})
			.then((result) => {
				isLoadingSet(false);
				areasSet(result.data);
			});
		axios
			.get("/cities", {
				headers: {
					lang: i18n.language,
				},
			})
			.then((result) => {
				isLoadingSet(false);
				citiesSet(result.data);
				filteredCitiesSet(result.data);
			});
	}, []);

	useEffect(() => {
		if (area) {
			filteredCitiesSet(cities.filter((i) => i.area_id == area));
			filteredBranchesSet(branches.filter((i) => i.area_id == area));
			citySet("");
			selectedBranchSet("");
		} else {
			filteredCitiesSet(cities);
			filteredBranchesSet(branches);
			citySet("");
			selectedBranchSet("");
		}
	}, [area]);

	useEffect(() => {
		if (city) {
			filteredBranchesSet(branches.filter((i) => i.city_id == city));
			selectedBranchSet("");
		} else {
			filteredBranchesSet(branches);
			selectedBranchSet("");
		}
	}, [city]);

	useEffect(() => {
		if (selectedBranch) {
			isLoadingBranchSet(true);
			axios.get(`/branches/${selectedBranch}`).then((result) => {
				isLoadingBranchSet(false);
				selectedBranchResultSet(result.data);
			});
		}
	}, [selectedBranch]);

	return (
		<div className="container mt-5">
			{isLoading ? (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-danger"
						style={{
							width: "100px",
							height: "100px",
						}}
						role="status"
					></div>
				</div>
			) : (
				<>
					<h1 className="display-4 mb-5">{t("ourBranches")}</h1>
					<div className="row">
						<div className="col-md-4 col-xs-12">
							<label>{t("area")}</label>
							<select
								className="form-control"
								value={area}
								style={{ height: "50px" }}
								onChange={(e) => areaSet(e.target.value)}
							>
								<option value=""> --{t("allAreas")}-- </option>
								{areas.map((item) => (
									<option key={item.id} value={item.id}>
										{item["name_" + i18n.language]}
									</option>
								))}
							</select>

							<label>{t("city")}</label>
							<select
								className="form-control"
								required
								value={city}
								style={{ height: "50px" }}
								onChange={(e) => citySet(e.target.value)}
							>
								<option value=""> --{t("allCities")}-- </option>
								{filteredCities.map((item) => (
									<option key={item.id} value={item.id}>
										{item["name_" + i18n.language]}
									</option>
								))}
							</select>

							<label>{t("branch")}</label>
							<select
								className="form-control"
								required
								value={selectedBranch}
								style={{ height: "50px" }}
								onChange={(e) =>
									selectedBranchSet(e.target.value)
								}
							>
								<option value="">--{t("allBranches")}--</option>
								{filteredBranches.map((item) => (
									<option key={item.id} value={item.id}>
										{item.name}
									</option>
								))}
							</select>
							{isLoadingBranch ? (
								<div className="d-flex justify-content-center mt-5">
									<div
										className="spinner-border text-danger"
										style={{
											width: "100px",
											height: "100px",
										}}
										role="status"
									></div>
								</div>
							) : (
								<>
									{selectedBranchResult && (
										<>
											<h4 className="font-weight-normal mt-5">
												<BiStore />{" "}
												{
													selectedBranchResult[
														"name_" + i18n.language
													]
												}
											</h4>
											<p className="mt-3 text-secondary">
												<AiOutlinePhone />{" "}
												{selectedBranchResult.phone}
											</p>
											<IoLocationOutline />{" "}
											{
												selectedBranchResult[
													"address_" + i18n.language
												]
											}
											<br />
											<a
												target="blank"
												href={`https://www.google.com/maps/place/${selectedBranchResult.latitude},${selectedBranchResult.longitude}`}
												className="btn btn-outline-secondary mt-3"
											>
												View on Google Maps
											</a>
										</>
									)}
								</>
							)}
						</div>
						<div className="col-md-8 col-xs-12 mt-4">
							<MapContainer
								center={[25.285242404482357, 45.99322077113195]}
								zoom={5}
								style={{ height: "500px" }}
								attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								attributionControl={false}
							>
								<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

								{filteredBranches.map((branch) => (
									<Marker
										key={branch.id}
										position={[
											Number(branch.lat),
											Number(branch.long),
										]}
										eventHandlers={{
											click: (e) => {
												selectedBranchSet(branch.id);
											},
										}}
									>
										<Popup>
											<strong> {branch.name} </strong>
										</Popup>
									</Marker>
								))}
							</MapContainer>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default Branches;
