import React from "react";

function Loading() {
	return (
		<div
			style={{
				width: "100%",
				height: "100vh",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<div
				className="spinner-border text-danger"
				style={{ width: "100px", height: "100px" }}
				role="status"
			></div>
		</div>
	);
}

export default Loading;
