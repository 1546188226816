import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import actionTypes from "../redux/actionTypes";
import { Link } from "react-router-dom";

function Login({ setUser, loginRequired }) {
	const { t } = useTranslation();
	const [email, emailSet] = useState("");
	const [password, passwordSet] = useState("");
	const [errors, errorsSet] = useState([]);
	const [errorMessage, errorMessageSet] = useState("");
	const [isSubmitting, isSubmittingSet] = useState(false);

	function handleSubmit(e) {
		e.preventDefault();
		errorsSet([]);
		isSubmittingSet(true);
		axios
			.post("/auth/login", {
				email: email,
				password: password,
			})
			.then((result) => {
				isSubmittingSet(false);
				localStorage.setItem("token", result.data.token);
				axios.defaults.headers.common[
					"Authorization"
				] = `Bearer ${result.data.token}`;

				setUser(true);
			})
			.catch((err) => {
				isSubmittingSet(false);
				if (err.response.data.errors) {
					errorsSet(err.response.data.errors);
				}
				errorMessageSet(err.response.data.message);
			});
	}
	return (
		<div className="container d-flex flex-column justify-content-center p-3 align-items-center mt-5">
			<div className="rounded shadow w-100" style={{ maxWidth: "500px" }}>
				<div className="p-5">
					<h1
						className="display-4 mb-5 text-center"
						style={{ fontSize: "25px" }}
					>
						{t("signin")}
					</h1>
					{loginRequired && (
						<div className="alert alert-danger" role="alert">
							{t("loginRequired")}
						</div>
					)}
					{errorMessage && (
						<div className="alert alert-danger" role="alert">
							{errorMessage}
						</div>
					)}
					<form onSubmit={handleSubmit}>
						<div className="form-group">
							<label>{t("email")}</label>
							<input
								type="email"
								className="form-control"
								value={email}
								onChange={(e) => emailSet(e.target.value)}
							/>
							{errors["email"] && (
								<p className="text-danger">{errors["email"]}</p>
							)}
						</div>
						<div className="form-group">
							<label>{t("password")}</label>
							<input
								type="password"
								className="form-control"
								value={password}
								onChange={(e) => passwordSet(e.target.value)}
							/>
							{errors["password"] && (
								<p className="text-danger">
									{errors["password"]}
								</p>
							)}
						</div>

						<button
							className="btn btn-danger btn-block mb-4"
							type="submit"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span
									className="spinner-border spinner-border-sm mr-2"
									role="status"
								></span>
							)}
							{t("login")}
						</button>
						<Link className="text-muted" to="/register">
							{t("createAccountNow")}
						</Link>
					</form>
				</div>
			</div>
		</div>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		setUser: (user) => dispatch({ type: actionTypes.setUser, user: user }),
	};
}

export default connect(null, mapDispatchToProps)(Login);
