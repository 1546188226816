import React, { useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

function Gallery() {
	useLayoutEffect(() => window.scroll(0, 0), []);
	const { t, i18n } = useTranslation();
	const [isLoading, isLoadingSet] = useState(true);
	const [catalogs, catalogsSet] = useState([]);

	const [pages, pagesSet] = useState(0);
	const [currentPage, currentPageSet] = useState(1);

	useEffect(() => {
		isLoadingSet(true);
		setTimeout(() => {
			axios
				.get("/catalogs", {
					params: {
						page: currentPage,
					},
				})
				.then((result) => {
					catalogsSet(result.data.data);
					isLoadingSet(false);
					pagesSet(result.data.meta.last_page);
					currentPageSet(result.data.meta.current_page);
					window.scroll(0, 0);
				});
		}, 100);
	}, [i18n.language, currentPage]);
	return (
		<div className="container mt-5">
			{isLoading ? (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-danger"
						style={{
							width: "100px",
							height: "100px",
						}}
						role="status"
					></div>
				</div>
			) : (
				<>
					<h1 className="display-4 mb-5">{t("gallery")}</h1>
					<div className="row mt-5">
						{catalogs?.map((catalog) => (
							<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
								<div className="card custom__card mb-4">
									<img
										src={catalog.image}
										className="card-img-top"
										alt={catalog.name}
										style={{
											height: "200px",
											width: "100%",
											objectFit: "cover",
										}}
									/>
									<div className="card-body">
										<h5 className="card-title">
											{catalog.name}
										</h5>

										<Link
											to={`/catalog/${catalog.id}`}
											className="btn btn-danger"
										>
											{t("details")}
										</Link>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className="d-flex justify-content-center">
						<Pagination
							count={pages}
							color="secondary"
							shape="rounded"
							page={currentPage}
							onChange={(e, value) => currentPageSet(value)}
							hidePrevButton
							hideNextButton
						/>
					</div>
				</>
			)}
		</div>
	);
}

export default Gallery;
