import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import slide1 from "../assets/slide-1.jpg";
import slide2 from "../assets/slide-2.jpg";
import slide3 from "../assets/slide-3.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LandingPageCarousel({ sliderImages }) {
	useEffect(() => console.log(sliderImages), []);
	return (
		<Carousel touch={false}>
			{sliderImages?.map((item) => (
				<Carousel.Item interval={5000}>
					<img
						className="d-block w-100"
						src={item.image}
						alt=""
						style={{
							height: "512px",
							objectPosition: "50% 50%",
							objectFit: "cover",
						}}
					/>

					<Carousel.Caption
						style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
					>
						<h3>{item.title}</h3>
						<p>{item.description}</p>
						<a href={item.link_url} className="btn btn-danger">
							{item.link_title}
						</a>
					</Carousel.Caption>
				</Carousel.Item>
			))}
			{/* <Carousel.Item interval={5000}>
				<img
					className="d-block w-100"
					src={slide1}
					alt="First slide"
					style={{
						height: "512px",
						objectPosition: "50% 50%",
						objectFit: "cover",
					}}
				/>
				
				<Carousel.Caption
					style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
				>
					<h3>{t("slide1Title")}</h3>
					<p>{t("slide1Description")}</p>
					<Link to="/register" className="btn btn-danger">
						{t("registerNow")}
					</Link>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item interval={5000}>
				<img
					className="d-block w-100"
					src={slide2}
					alt="Second slide"
					style={{
						height: "512px",
						objectPosition: "50% 50%",
						objectFit: "cover",
					}}
				/>

				<Carousel.Caption
					style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
				>
					<h3>{t("slide2Title")}</h3>
					<p>{t("slide2Description")}</p>
					<Link to="/shop" className="btn btn-danger">
						{t("shopNow")}
					</Link>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item interval={5000}>
				<img
					className="d-block w-100"
					src={slide3}
					alt="First slide"
					style={{
						height: "512px",
						objectPosition: "50% 50%",
						objectFit: "cover",
					}}
				/>

				<Carousel.Caption
					style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
				>
					<h3>{t("slide3Title")}</h3>
					<p>{t("slide3Description")}</p>
					<Link to="/branches" className="btn btn-danger">
						{t("ourBranches")}
					</Link>
				</Carousel.Caption>
			</Carousel.Item> */}
		</Carousel>
	);
}

export default LandingPageCarousel;
