import React, { useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import ProductCard from "./ProductCard";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { BiSearchAlt2 } from "react-icons/bi";

function Shop({ location }) {
	useLayoutEffect(() => window.scroll(0, 0), []);
	const { categoryId } = useParams();
	const { t, i18n } = useTranslation();
	const [isLoading, isLoadingSet] = useState(false);
	const [isCategoriesLoading, isCategoriesLoadingSet] = useState(false);
	const [categories, categoriesSet] = useState([]);
	const [products, productsSet] = useState([]);
	const [search, searchSet] = useState("");
	const [categorySelect, categorySelectSet] = useState("all");
	const [pages, pagesSet] = useState(0);
	const [currentPage, currentPageSet] = useState(1);

	useEffect(() => {
		isLoadingSet(true);
		productsSet([]);
		setTimeout(() => {
			if (categorySelect !== "all") {
				axios
					.get("/products", {
						params: {
							category_id: categorySelect,
							search: search ? search : undefined,
							page: currentPage,
						},
					})
					.then((result) => {
						productsSet(result.data.data);
						window.scroll(0, 0);
						pagesSet(result.data.meta.last_page);
						isLoadingSet(false);
					})
					.catch((err) => console.log(err));
			} else {
				axios
					.get("/products", {
						params: {
							search: search ? search : undefined,
							page: currentPage,
						},
					})
					.then((result) => {
						productsSet(result.data.data);
						pagesSet(result.data.meta.last_page);
						isLoadingSet(false);
					});
			}
		}, 100);
	}, [i18n.language, categorySelect, search, currentPage]);

	useEffect(() => {
		isCategoriesLoadingSet(true);
		categoriesSet([]);
		setTimeout(() => {
			axios.get("/categories").then((result) => {
				categoriesSet(result.data.data);
				isCategoriesLoadingSet(false);
			});
		}, 100);
	}, [i18n.language]);

	useEffect(() => {
		if (categoryId) {
			categorySelectSet(categoryId);
		}

		let keyword = new URLSearchParams(location.search).get("search");
		if (keyword) {
			searchSet(keyword);
		}
	}, []);

	return (
		<div className="container mt-5">
			<h1 className="display-4">{t("shop")}</h1>
			<div className="row mt-5">
				<div className="col-md-3 col-xs-12">
					<form className="mb-5" style={{ maxWidth: "400px" }}>
						<div className="input-group">
							<input
								className="form-control flex-fill border-right-0"
								type="search"
								value={search}
								onChange={(e) => searchSet(e.target.value)}
								placeholder={t("search")}
								aria-label="Search"
							/>
							<div className="input-group-append">
								<button
									className="btn btn-danger"
									type="submit"
								>
									<BiSearchAlt2
										style={{ fontSize: "20px" }}
									/>
								</button>
							</div>
						</div>
					</form>
					<div className="card mb-5">
						<div className="card-body">
							<div className="d-flex">
								<h5 className="card-title">{t("category")}</h5>
								{/* <Dropdown>
									<Dropdown.Toggle
										variant="success"
										id="dropdown-basic"
									>
										Dropdown Button
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item href="#/action-1">
											Action
										</Dropdown.Item>
										<Dropdown.Item href="#/action-2">
											Another action
										</Dropdown.Item>
										<Dropdown.Item href="#/action-3">
											Something else
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown> */}
							</div>
							<p className="card-text mt-4">
								{!isCategoriesLoading && (
									<>
										<div>
											<input
												type="radio"
												id="category-all"
												checked={
													categorySelect === "all"
												}
												onChange={() =>
													categorySelectSet("all")
												}
												name="category"
											/>
											<label
												htmlFor="category-all"
												className="ml-2"
											>
												{t("all")}
											</label>
										</div>
										{categories?.map((category) => (
											<div key={category.id}>
												<input
													type="radio"
													id={`category-${category.id}`}
													name="category"
													checked={
														categorySelect ==
														category.id
													}
													onChange={() =>
														categorySelectSet(
															category.id
														)
													}
												/>
												<label
													htmlFor={`category-${category.id}`}
													className="ml-2"
												>
													{category.name}
												</label>
											</div>
										))}
									</>
								)}

								{isCategoriesLoading && (
									<div className="d-flex justify-content-center mt-3">
										<div
											className="spinner-border text-danger"
											style={{
												width: "50px",
												height: "50px",
											}}
											role="status"
										></div>
									</div>
								)}
							</p>
						</div>
					</div>
				</div>
				<div className="col-md-9 col-xs-12">
					<div className="row">
						{products.map((product) => (
							<div
								key={product.id}
								className="col-sm-6 col-xs-12 mb-5"
							>
								<ProductCard product={product} />
							</div>
						))}
						{!isLoading && products.length < 1 && (
							<div
								className="mt-5 text-center text-muted w-100"
								style={{ fontSize: "25px" }}
							>
								{t("noProducts")}
							</div>
						)}
					</div>
					{isLoading && (
						<div className="d-flex justify-content-center mt-5">
							<div
								className="spinner-border text-danger"
								style={{
									width: "100px",
									height: "100px",
								}}
								role="status"
							></div>
						</div>
					)}

					{!isLoading && (
						<div className="d-flex justify-content-center">
							<Pagination
								count={pages}
								color="secondary"
								shape="rounded"
								page={currentPage}
								onChange={(e, value) => currentPageSet(value)}
								hidePrevButton
								hideNextButton
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default Shop;
