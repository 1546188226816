import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import visitImg from "../assets/visit-request.png";
import axios from "axios";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

function VisitRequest() {
	useLayoutEffect(() => window.scroll(0, 0), []);

	const { t } = useTranslation();
	const history = useHistory();
	const [name, nameSet] = useState("");
	const [email, emailSet] = useState("");
	const [note, noteSet] = useState("");
	const [phone, phoneSet] = useState("");
	const [address, addressSet] = useState("");
	const [placeType, placeTypeSet] = useState("consultation");
	const [errors, errorsSet] = useState([]);
	const [isSubmitting, isSubmittingSet] = useState(false);

	function handleSubmit() {
		isSubmittingSet(true);
		errorsSet([]);
		axios
			.post("/visits", {
				note: note,
				phone: phone,
				address: address,
				type: placeType,
				name: name,
				email: email,
			})
			.then(() => {
				isSubmittingSet(false);
				swal({
					title: t("requestReceived"),
					text: t("requestReceivedMessage"),
					icon: "success",
					button: false,
				}).then(() => history.push("/"));
			})
			.catch((err) => {
				isSubmittingSet(false);
				console.log(err.response);
				errorsSet(err.response.data.errors);
			});
	}

	return (
		<div className="container mt-5">
			<h1>{t("ourServices")}</h1>
			<img
				src={visitImg}
				alt=""
				style={{ maxHeight: "200px", objectFit: "contain" }}
				className="w-100 mt-5 mb-5"
			/>

			<div
				className="mr-auto ml-auto outline-none"
				style={{ maxWidth: "500px" }}
			>
				<div className="card-body">
					<label className="mt-4">{t("name")}</label>
					<input
						type="text"
						className="form-control"
						value={name}
						onChange={(e) => nameSet(e.target.value)}
						placeholder={t("name")}
					/>
					{errors["name"] && (
						<p className="text-danger">{errors["name"]}</p>
					)}

					<label className="mt-4">{t("email")}</label>
					<input
						type="text"
						className="form-control"
						value={email}
						onChange={(e) => emailSet(e.target.value)}
						placeholder={t("email")}
					/>
					{errors["email"] && (
						<p className="text-danger">{errors["email"]}</p>
					)}

					<label className="mt-4">{t("serviceType")}</label>
					<select
						className="form-control"
						required
						style={{ height: "50px" }}
						value={placeType}
						onChange={(e) => placeTypeSet(e.target.value)}
					>
						<option value="consultation">
							{t("consultation")}
						</option>
						<option value="home">{t("homePlace")}</option>
						<option value="business">{t("businessPlace")}</option>
					</select>

					<label className="mt-4">{t("phone")}</label>
					<input
						type="text"
						className="form-control"
						value={phone}
						onChange={(e) => phoneSet(e.target.value)}
						placeholder={t("phone")}
					/>
					{errors["phone"] && (
						<p className="text-danger">{errors["phone"]}</p>
					)}

					<label className="mt-4">{t("address")}</label>
					<input
						type="text"
						className="form-control"
						value={address}
						onChange={(e) => addressSet(e.target.value)}
						placeholder={t("address")}
					/>
					{errors["address"] && (
						<p className="text-danger">{errors["address"]}</p>
					)}

					<label className="mt-4">{t("additionalNotes")}</label>
					<textarea
						className="form-control"
						rows="5"
						placeholder={t("additionalNotesPlaceholder")}
						value={note}
						onChange={(e) => noteSet(e.target.value)}
					></textarea>

					<button
						className="btn btn-danger ml-auto mr-auto d-block mt-5 btn-lg"
						disabled={isSubmitting}
						onClick={handleSubmit}
					>
						{isSubmitting && (
							<span
								className="spinner-border spinner-border-sm mr-2 mb-2"
								role="status"
							></span>
						)}
						{t("sendRequest")}
					</button>
				</div>
			</div>
		</div>
	);
}

export default VisitRequest;
