import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import logo from "../assets/logo.png";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BiHomeAlt, BiStore, BiSearchAlt2 } from "react-icons/bi";
import { RiGalleryLine } from "react-icons/ri";
import {
	FaUserCircle,
	FaFacebookF,
	FaTwitter,
	FaInstagram,
	FaYoutube,
	FaPhone,
} from "react-icons/fa";
// import { MdLanguage } from "react-icons/md";
import { connect } from "react-redux";
import NavbarCustom from "./Navbar";

function Header({ user, cart, infoData }) {
	const [show, handleShow] = useState(false);
	const { t, i18n } = useTranslation();
	// useEffect(() => {
	// 	window.addEventListener("scroll", () => {
	// 		if (window.scrollY > 300) {
	// 			handleShow(true);
	// 		} else {
	// 			handleShow(false);
	// 		}
	// 	});

	// 	return () => {
	// 		window.removeEventListener("scroll", null);
	// 	};
	// }, []);
	function changeLanguage() {
		if (i18n.language === "en") {
			i18n.changeLanguage("ar");
			localStorage.setItem("lang", "ar");
		} else {
			i18n.changeLanguage("en");
			localStorage.setItem("lang", "en");
		}
	}
	return (
		<>
			<Navbar
				collapseOnSelect
				sticky="top"
				expand="lg"
				className="custom__navbar"
			>
				<Container>
					<Navbar.Brand as={Link} to="/">
						<img
							src={logo}
							height="30"
							className={`d-inline-block align-top mr-5 navLogo ${
								show ? "navLogo__small" : ""
							}`}
							alt="React Bootstrap logo"
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="w-100 justify-content-between align-items-lg-center">
							{/* <form className="flex-fill m-2 mr-5 d-lg-none d-flex">
								<div className="input-group">
									<input
										className="form-control flex-fill border-right-0"
										type="search"
										placeholder={t("search")}
										aria-label="Search"
									/>
									<div className="input-group-append">
										<button
											className="btn btn-danger"
											type="submit"
										>
											<BiSearchAlt2
												style={{ fontSize: "20px" }}
											/>
										</button>
									</div>
								</div>
							</form> */}
							<div></div>
							<div
								className="d-flex text-secondary d-flex align-items-center"
								style={{ fontSize: "20px" }}
							>
								<FaPhone className="mr-2" />{" "}
								{infoData?.main_phone}
							</div>
							<div className="d-flex">
								<a
									className="nav-link mr-2"
									target="blank"
									href={infoData?.facebook}
								>
									<FaFacebookF className="nav-icon" />
								</a>
								<a
									className="nav-link mr-2"
									target="blank"
									href={infoData?.twitter}
								>
									<FaTwitter className="nav-icon" />
								</a>
								<a
									className="nav-link mr-2"
									target="blank"
									href={infoData?.instagram}
								>
									<FaInstagram className="nav-icon" />
								</a>
								<a
									className="nav-link mr-2"
									target="blank"
									href={infoData?.youtube}
								>
									<FaYoutube className="nav-icon" />
								</a>
							</div>
							<ul className="navbar-nav d-lg-none">
								<Nav.Link
									to="/"
									exact
									eventKey="1"
									className="nav-link d-flex align-items-center"
									as={NavLink}
								>
									<BiHomeAlt className="mr-1 nav-icon" />
									{t("home")}
								</Nav.Link>

								<Nav.Link
									to="/shop"
									exact
									eventKey="2"
									className="nav-link d-flex align-items-center"
									as={NavLink}
								>
									<BiStore className="mr-1 nav-icon" />
									{t("shop")}
								</Nav.Link>

								<Nav.Link
									to="/gallery"
									exact
									eventKey="3"
									className="nav-link d-flex align-items-center"
									as={NavLink}
								>
									<RiGalleryLine className="mr-1 nav-icon" />
									{t("gallery")}
								</Nav.Link>

								<Nav.Link
									to="/branches"
									exact
									eventKey="7"
									className="nav-link d-flex align-items-center"
									as={NavLink}
								>
									<BiStore className="mr-1 nav-icon" />
									{t("ourBranches")}
								</Nav.Link>
							</ul>

							<ul className="navbar-nav">
								{user ? (
									<Nav.Link
										to="/home"
										exact
										eventKey="5"
										className="nav-link d-flex align-items-center"
										as={NavLink}
									>
										<FaUserCircle className="mr-1 nav-icon" />
										{t("myAccount")}
									</Nav.Link>
								) : (
									<Nav.Link
										to="/login"
										exact
										eventKey="5"
										className="nav-link d-flex align-items-center"
										as={NavLink}
									>
										<FaUserCircle className="mr-1 nav-icon" />
										{t("signin")}
									</Nav.Link>
								)}

								<Nav.Link
									to="/cart"
									exact
									eventKey="6"
									className="nav-link d-flex align-items-center mr-1"
									as={NavLink}
								>
									<AiOutlineShoppingCart className="mr-1 nav-icon" />
									<span className="mr-2 d-lg-none">
										{t("cart")}
									</span>
									<Badge variant="danger">{cart}</Badge>
								</Nav.Link>

								<Link
									to="#"
									data-rb-event-key="/shop"
									className="nav-link d-flex align-items-center"
									onClick={changeLanguage}
								>
									<img
										src={
											i18n.language === "en"
												? "https://www.countryflags.io/sa/shiny/24.png"
												: "https://www.countryflags.io/gb/shiny/24.png"
										}
										className="mr-1"
										alt=""
									/>
									{i18n.language === "en"
										? "العربية"
										: "English"}
								</Link>

								{/* <Link
									to="#"
									data-rb-event-key="/shop"
									className="nav-link d-flex align-items-center"
									onClick={changeLanguage}
								>
									{t("logout")}
								</Link> */}
							</ul>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<NavbarCustom show={show} />
		</>
	);
}

function mapStateToProps(state) {
	return {
		user: state.user,
		cart: state.cart,
	};
}

export default connect(mapStateToProps)(Header);
