import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { FaWhatsapp } from "react-icons/fa";

function Footer({ infoData }) {
	const { t, i18n } = useTranslation();
	const [email, setEmail] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	function handleSubmit(e) {
		e.preventDefault();
		setIsLoading(true);
		axios.post("/subscriptions", { email: email }).then(() => {
			setIsLoading(false);
			setEmail("");
			swal({
				title: t("thankYou"),
				text: t("messageAlert"),
				icon: "success",
				button: false,
			});
		});
	}
	return (
		<div className="footer text-muted mt-5">
			<div className="container">
				<div className="row">
					<div className="col-md-4 col-xs-12 mt-5">
						<img src={logo} alt="" className="footer__logo" />
						<p className="mt-3">{infoData?.main_phone}</p>
						<p>
							{" "}
							{infoData
								? infoData["main_address_" + i18n.language]
								: ""}{" "}
						</p>

						<a
							target="_blank"
							href="https://wa.me/966920010422"
							style={{ textDecoration: "none" }}
						>
							<div className="footer__whatsapp">
								<FaWhatsapp /> {t("contactUsWhatsapp")}
							</div>
						</a>
					</div>
					<div className="col-md-4 col-xs-12 mt-5">
						<h3>{t("siteMap")}</h3>
						<Link className="text-muted d-block" to="/">
							{t("home")}
						</Link>
						<Link className="text-muted d-block" to="/shop">
							{t("shop")}
						</Link>
						<Link className="text-muted d-block" to="/gallery">
							{t("gallery")}
						</Link>
						<Link
							className="text-muted d-block"
							to="/specialist-visit"
						>
							{t("specialistVisit")}
						</Link>
					</div>
					<div className="col-md-4 col-xs-12 mt-5">
						<h3>{t("stayInformed")}</h3>
						<p>{t("stayInformedMessage")}</p>
						<form onSubmit={handleSubmit}>
							<input
								type="email"
								className="form-control mb-3"
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder={t("email")}
							/>
							<button
								className="btn btn-danger"
								disabled={isLoading}
							>
								{isLoading && (
									<span
										className="spinner-border mr-2"
										role="status"
									></span>
								)}
								{t("subscribe")}
							</button>
						</form>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-12 text-center w-100 mt-5">
						{t("footer")}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;
