import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LandingPage from "./components/LandingPage";
import { useTranslation } from "react-i18next";
import "./bootstrap.all.css";
import "./stylesheets/index.scss";
import axios from "axios";
import Shop from "./components/Shop";
import Colors from "./components/Colors";
import Login from "./components/Login";
import Register from "./components/Register";
import { connect } from "react-redux";
import actionTypes from "./redux/actionTypes";
import ProtectedRoute from "./components/ProtectedRoute";
import Home from "./components/Home";
import Cart from "./components/Cart";
import VisitRequest from "./components/VisitRequest";
import Settings from "./components/Settings";
import AuthRoute from "./components/AuthRoute";
import Product from "./components/Product";
import Branches from "./components/Branches";
import Gallery from "./components/Gallery";
import Catalog from "./components/Catalog";
import Loading from "./components/Loading";
import Checkout from "./components/Checkout";

function App({ setUser, setCart, setBranches }) {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const [isLoadingContent, isLoadingContentSet] = useState(true);
	const [sliderImages, sliderImagesSet] = useState([]);
	const [companyFeatures, companyFeaturesSet] = useState([]);
	const [branches, branchesSet] = useState([]);
	const [infoData, infoDataSet] = useState();

	//axios.defaults.baseURL = "http://localhost:8000/api";
	//axios.defaults.baseURL = "https://backend.markcodes.xyz/api";
	axios.defaults.baseURL = "https://services.oscarpaints.com/api";

	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			if (401 === error.response?.status) {
				localStorage.removeItem("token");
				setUser(false);
				// history.push("/");
			}

			return Promise.reject(error);
		}
	);

	useEffect(() => {
		if (i18n.language === "en") {
			document.body.className = "ltr";
			document.body.dir = "ltr";
			document.title =
				"Oscar Paints | Shop Now online Interior and Exterior Paints in Saudi Arabia ";
		} else {
			document.body.className = "rtl";
			document.body.dir = "rtl";
			document.title =
				"دهانات اوسكار | تسوق الآن أون لاين دهانات داخلية وخارجية في السعودية";
		}

		setTimeout(() => {
			const sliderRequest = axios.get("/slider-images", {
				headers: {
					lang: i18n.language,
				},
			});
			const featuresRequest = axios.get("/company-features", {
				headers: {
					lang: i18n.language,
				},
			});

			const branchesRequest = axios.get("/branches", {
				headers: {
					lang: i18n.language,
				},
			});

			const infoRequest = axios.get("/info", {
				headers: {
					lang: i18n.language,
				},
			});

			axios
				.all([
					sliderRequest,
					featuresRequest,
					branchesRequest,
					infoRequest,
				])
				.then(
					axios.spread((...responses) => {
						sliderImagesSet(responses[0].data.data);
						companyFeaturesSet(responses[1].data.data);
						branchesSet(responses[2].data.data);
						infoDataSet(responses[3].data.data);
						isLoadingContentSet(false);
					})
				);
		}, 100);
	}, [i18n.language]);

	useEffect(() => {
		axios.defaults.headers.common["lang"] = i18n.language;
	}, [i18n.language]);

	useEffect(() => {
		if (localStorage.getItem("lang")) {
			i18n.changeLanguage(localStorage.getItem("lang"));
		}

		if (localStorage.getItem("token")) {
			axios.defaults.headers.common[
				"Authorization"
			] = `Bearer ${localStorage.getItem("token")}`;
			setUser(true);
			axios.get("/cart/count").then((result) => {
				setCart(result.data);
			});
		}
	}, []);

	return (
		<BrowserRouter>
			{isLoadingContent ? (
				<Loading />
			) : (
				<div className="app">
					<Header infoData={infoData} />
					<div className="app__middle">
						<Switch>
							<Route path="/" exact>
								<LandingPage
									sliderImages={sliderImages}
									companyFeatures={companyFeatures}
									branches={branches}
									infoData={infoData}
								/>
							</Route>
							<Route path="/visit-request" exact>
								<VisitRequest />
							</Route>
							<Route
								path="/shop/category/:categoryId"
								exact
								component={Shop}
							/>
							<Route path="/shop" exact component={Shop} />
							<Route path="/branches" exact>
								<Branches branches={branches} />
							</Route>

							<Route path="/colors" exact component={Colors} />
							<Route path="/gallery" exact component={Gallery} />

							<Route
								path="/catalog/:catalogId"
								exact
								component={Catalog}
							/>
							<Route
								path="/product/:productId"
								exact
								component={Product}
							/>

							<AuthRoute path="/login" Component={Login} />
							<AuthRoute path="/register" Component={Register} />
							<ProtectedRoute path="/home" Component={Home} />
							<ProtectedRoute path="/cart" Component={Cart} />
							<ProtectedRoute
								path="/checkout"
								Component={Checkout}
							/>

							<ProtectedRoute
								path="/settings"
								Component={Settings}
							/>
						</Switch>
					</div>
					<Footer infoData={infoData} />
				</div>
			)}
		</BrowserRouter>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		setUser: (user) => dispatch({ type: actionTypes.setUser, user: user }),
		setCart: (cart) => dispatch({ type: actionTypes.setCart, cart: cart }),
		setBranches: (branches) =>
			dispatch({ type: actionTypes.setBranches, branches: branches }),
	};
}

export default connect(null, mapDispatchToProps)(App);
