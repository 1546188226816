import actionTypes from "./actionTypes";

const initalState = {
	user: false,
	cart: 0,
	branches: [],
};

const reducer = (state = initalState, action) => {
	switch (action.type) {
		case actionTypes.setUser:
			return {
				...state,
				user: action.user,
			};

		case actionTypes.setCart:
			return {
				...state,
				cart: action.cart,
			};

		case actionTypes.setBranches:
			return {
				...state,
				branches: action.branches,
			};

		default:
			return state;
	}
};

export default reducer;
