import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import axios from "axios";
import CartItem from "./CartItem";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import actionTypes from "../redux/actionTypes";
import Pagination from "@material-ui/lab/Pagination";

function Cart({ setCart }) {
	useLayoutEffect(() => window.scroll(0, 0), []);
	const { t, i18n } = useTranslation();
	const [isLoading, isLoadingSet] = useState(true);
	const [totalPrice, totalPriceSet] = useState("0");
	const [totalProducts, totalProductsSet] = useState("0");
	const [cart, cartSet] = useState([]);

	const [pages, pagesSet] = useState(0);
	const [currentPage, currentPageSet] = useState(1);

	useEffect(() => {
		isLoadingSet(true);
		setTimeout(() => {
			axios
				.get("/cart", {
					params: {
						page: currentPage,
					},
				})
				.then((result) => {
					isLoadingSet(false);
					setCart(result.data.meta.total);
					cartSet(result.data.data);
					totalProductsSet(result.data.meta.total);
					pagesSet(result.data.meta.last_page);
					currentPageSet(result.data.meta.current_page);
					window.scroll(0, 0);
				});
		}, 100);
	}, [i18n.language, currentPage]);

	function refreshCart() {
		cartSet([]);
		isLoadingSet(true);
		axios.get("/cart").then((result) => {
			cartSet(result.data.data);
			totalProductsSet(result.data.meta.total);
			setCart(result.data.meta.total);
			axios.get("/cart/total").then((result) => {
				totalPriceSet(result.data[0].total);
				isLoadingSet(false);
			});
		});
	}

	useEffect(() => {
		axios.get("/cart/total").then((result) => {
			totalPriceSet(result.data[0].total);
		});
	}, []);
	return (
		<Layout>
			<h1>{t("cart")}</h1>
			{isLoading ? (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-danger"
						style={{
							width: "100px",
							height: "100px",
						}}
						role="status"
					></div>
				</div>
			) : (
				<>
					{cart.length > 0 ? (
						<>
							<div className="d-flex flex-md-row flex-sm-column align-items-center justify-content-between">
								<h3 className="mt-5 mb-5 font-weight-normal text-secondary">
									{t("total")} {totalPrice} {t("sar")}
									{/* {totalProducts} {t("product_s")} */}
								</h3>

								<Link to="/checkout" className="btn btn-danger">
									{t("proceedToCheckout")}
								</Link>
							</div>
							{cart.map((item, idx) => (
								<CartItem
									key={idx}
									item={item}
									refreshCart={refreshCart}
								/>
							))}

							<div className="d-flex justify-content-center">
								<Pagination
									count={pages}
									color="secondary"
									shape="rounded"
									page={currentPage}
									onChange={(e, value) =>
										currentPageSet(value)
									}
									hidePrevButton
									hideNextButton
								/>
							</div>
						</>
					) : (
						<>
							<h2
								className="text-muted text-center font-weight-normal"
								style={{ marginTop: "70px" }}
							>
								{t("noCart")}
							</h2>
						</>
					)}
				</>
			)}
		</Layout>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		setCart: (cart) => dispatch({ type: actionTypes.setCart, cart: cart }),
	};
}

export default connect(null, mapDispatchToProps)(Cart);
