import React, { useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

function Catalog() {
	useLayoutEffect(() => window.scroll(0, 0), []);
	const { t, i18n } = useTranslation();
	const [isLoading, isLoadingSet] = useState(true);
	const [catalog, catalogSet] = useState();
	const { catalogId } = useParams();

	useEffect(() => {
		isLoadingSet(true);
		setTimeout(() => {
			axios.get(`/catalogs/${catalogId}`).then((result) => {
				isLoadingSet(false);
				catalogSet(result.data.data);
			});
		});
	}, [i18n.language]);

	return (
		<div className="container">
			{isLoading ? (
				<div className="d-flex justify-content-center mt-5">
					<div
						className="spinner-border text-danger"
						style={{
							width: "100px",
							height: "100px",
						}}
						role="status"
					></div>
				</div>
			) : (
				<>
					<img
						src={catalog?.image}
						alt=""
						style={{
							height: "400px",
							width: "100%",
							objectFit: "cover",
						}}
					/>
					<h1 className="display-4 mt-3">{catalog?.name}</h1>
					<p className="mt-5 text-secondary">
						{catalog?.description}
					</p>
					<div className="text-left">
						<a
							href={catalog?.file}
							className="btn btn-danger mt-3 mb-3"
							download
							rel="noreferrer noopener"
							target="_blank"
						>
							{t("downloadCatalog")}
						</a>
					</div>
				</>
			)}
		</div>
	);
}

export default Catalog;
